import React from 'react'

export default function CircularProgress({ value, max , color }) {
    const radius = 50; // Radius of the circle
    const stroke = 8; // Thickness of the progress bar
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (value / max) * circumference;

  return <>
     <svg
      height={radius * 2}
      width={radius * 2}
      >
      <circle
        stroke="#EBEAEA"
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ max }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        stroke={color}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fill="black">
          <i class="fa-regular fa-clock"></i>
        {`${value }% `}
        </text>
    </svg>
  </>
}
