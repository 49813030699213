import React, { useState } from 'react'
import Error from '../../../Components/Used/Error'
export default function StepFour({ updateUserData, clearInputData, registerData, show, errors }) {

    return <>
        <form className={`container first-step-form form-steps ${show ? '' : 'd-none'}`}>
            <div className='row gap-2'>
                <div className='input-style col-lg-10'>
                    <input onChange={updateUserData} name='email' type='text' placeholder='البريد الالكتروني' value={registerData['email']} />
                    <div className={`clear-input ${registerData['email'] === '' ? 'd-none' : ''}`} onClick={() => clearInputData('email')}>
                        <i className="fa-solid fa-circle-xmark"></i>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className={`input-style ${errors['password'] ? 'error' : ''}`}>
                        <input onChange={updateUserData} name='password' type='password' placeholder='كلمة السر' value={registerData['password']} />
                        <div className={`clear-input ${registerData['password'] === '' ? 'd-none' : ''}`} onClick={() => clearInputData('password')}>
                            <i className="fa-solid fa-circle-xmark"></i>
                        </div>
                    </div>
                    <ul className={errors['password'] ? '' : 'd-none'}>
                        {
                            errors['password'] && Object.values(errors['password']).map(error => <li className='text-danger'> {error}  </li>)
                        }
                    </ul>
                </div>
                <div className="col-lg-5">
                    <div className={`input-style ${errors['password_confirmation'] ? 'error' : ''}`}>
                        <input onChange={updateUserData} name='password_confirmation' type='password' placeholder='تأكيد كلمة السر' />
                        <div className={`clear-input ${registerData['password_confirmation'] === '' ? 'd-none' : ''}`} onClick={() => clearInputData('password_confirmation')}>
                            <i className="fa-solid fa-circle-xmark"></i>
                        </div>
                    </div>
                    <ul className={errors['password_confirmation'] ? '' : 'd-none'}>
                        {
                            errors['password_confirmation'] && Object.values(errors['password_confirmation']).map(error => <li className='text-danger'> {error}  </li>)
                        }
                    </ul>
                </div>
                {/* <label htmlFor='attach' className='input-style upload col-lg-10'>
                    <input name='attach' type='file' className='d-none' id='attach' multiple />
                    <label htmlFor='attach' className='m-0  p-0'>
                        أرفع شهادة ميلادك / بطاقتك
                    </label>
                    <div className={`clear-input`} >
                        <i class="fa-solid fa-arrow-up-from-bracket"></i>
                    </div>
                </label> */}
            </div>
        </form>
    </>
}
