import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { RouterProvider } from 'react-router-dom';
import router from "./Router/Router.js";
import './AppDark.css';
import AuthContextProvider from './Context/AuthStore.js';
import DarkModeProvider from './Context/DarkModeContext.js';

function App() {
  return (
    <DarkModeProvider>
      <AuthContextProvider>
        <RouterProvider router={router} />
      </AuthContextProvider>
    </DarkModeProvider>
  );
}

export default App;
