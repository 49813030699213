import React from 'react'
import lightLogo from '../assets/nasef-assets/logo.svg'
import companyLogo from '../assets/company-logo.png'

export default function Footer() {
    return <>
        <footer className='footer'>
            <div className='container'>
                <div className='contact-section'>
                    <div className='footer-logo'>
                        <img className='w-100 h-100' alt='' src={lightLogo} />
                    </div>
                    <h4 className='text-center'>
                        تواصل معنا عبر
                    </h4>
                    <div className='row py-2 w-100 '>
                        {/* <div className='email col-lg-3 col-12 text-start'>
                        AhmedFadl@gmail.com
                        mahmoud-elgharabawy.com
                        <i className="fa-regular fa-envelope"></i>
                    </div> */}
                        {/* <div className='col-lg-3 col-12 text-end'>
                        01140 665 667
                        0111 35 333 06
                        <i className="fa-solid fa-phone-flip"></i>
                    </div> */}
                        <div className='col-lg-3 col-12 text-end'>
                            01080138026
                            <i className="fa-solid fa-phone-flip"></i>
                        </div>
                        {/* <div className='col-lg-3 col-12 text-end'>
                            01070099594
                            <i className="fa-solid fa-phone-flip"></i>
                        </div> */}
                        {/* <div className='col-lg-2 col-6'>
                        01140 665 667
                        <i className="fa-solid fa-phone-flip"></i>
                    </div>
                    <div className='col-lg-2 col-6'>
                        01140 665 667
                        <i className="fa-solid fa-phone-flip"></i>
                    </div>
                    <div className='col-lg-2 col-6'>
                        01140 665 667
                        <i className="fa-solid fa-phone-flip"></i>
                    </div> */}
                    </div>
                    <div className='footer-social-media py-1'>
                        <a href='https://www.facebook.com/NassefYoussef68' target='_blank'>
                            <i className="fa-brands fa-facebook"></i>
                        </a>
                        <a href='https://instagram.com/mrnassefyoussef ' target='_blank'>
                            <i className="fa-brands fa-instagram"></i>
                        </a>
                        <a href='https://www.tiktok.com/@mrnassefyoussef' target='_blank'>
                            <i className="fa-brands fa-tiktok"></i>
                        </a>
                        {/* <a href='https://twitter.com/MahmoudElGhar1?t=IUxCqzqqhq11wEXKnVJzaA&s=09' target='_blank'>
                            <i className="fa-brands fa-square-twitter"></i>
                        </a> */}
                        <a href='https://youtube.com/c/nassefyoussef ' target='_blank'>
                            <i className="fa-brands fa-youtube" ></i>
                        </a>
                        <a href='https://whatsapp.com/channel/0029Va5CYA4HFxP2DmJxLA1z ' target='_blank'>
                            <i className="fa-brands fa-whatsapp" ></i>
                        </a>
                    </div>
                </div>
                <div className='copyright'>
                    <div className='copyright-logo'>
                        <img className='w-100 h-100' src={companyLogo} alt='' />
                    </div>
                    <p>
                        حقوق النشر © 2024 لشركة Easy Tech . كل الحقوق محفوظة.
                    </p>
                </div>
            </div>
        </footer>
    </>
}