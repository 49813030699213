import axios from 'axios'
import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import config from '../config'
import { auth } from '../Context/AuthStore'
import Placeholder from '../Components/Used/Placeholder'
import { Navigate } from 'react-router-dom'

export default function AdminGourd(props) {

    const { userAuth } = useContext(auth)

    const [isAdmin, setIsAdmin] = useState(null)

    const admin = () => {
        axios.get(`${config.basURL}dashboard/check-user-promotion`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setIsAdmin(res.data.is_admin)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        admin()
    }, [userAuth])

    return <>
        {
            isAdmin === null ? <div className="subscription-gourd">
                <Placeholder />
            </div> : (isAdmin ? props.children : <Navigate to={'/user-profile'} />)
        }
    </>
}
