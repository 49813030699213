import './NasefStyle.css'
import teacherImage from '../../../assets/nasef-assets/12.png'
import orangeBg from '../../../assets/nasef-assets/yellow-bg.png'
import firstYaer from '../../../assets/nasef-assets/clint-patterson-CgIFBwOkApI-unsplash.jpg'
import secoundYaer from '../../../assets/nasef-assets/hans-reniers-lQGJCMY5qcM-unsplash.jpg'
import thirdYaer from '../../../assets/nasef-assets/alex-kondratiev-H9t723yPjYI-unsplash.jpg'
import { Link, useNavigate } from 'react-router-dom'

export default function NasefHome() {

    const navigate = useNavigate()

    let navigateTo = (url) => {
        navigate(url)
      }

    return <>
        <section className="nasef-home">
            <div className="container">
                <div className="right">
                    <div className="image">
                        {/* <img src={circle} alt="" className='circle-bg' /> */}
                        <img src={teacherImage} className='h-100' alt="" />
                    </div>
                </div>
                <div className="left">
                    <h1 className='fw-bold'>
                        <div className="icon">
                            <i className="fa-solid fa-flask"></i>
                        </div>
                        أ / ناصف يوسف
                        <img src={orangeBg} alt="" />
                    </h1>
                    <p>
                        الخلاصه في ماده الكيمياء
                    </p>
                    {/* <button className='view-all-courses' onClick={() => navigateTo('/courses')}>
                        <i className="ms-2 fa-solid fa-display"></i>
                        اكتشف جميع الكورسات
                    </button> */}
                    <button className='go-to-login' onClick={() => navigateTo('/auth')}>
                        <i className="ms-2 fa-solid fa-user-plus"></i>
                        قم بالتسجيل الان
                    </button>
                </div>
            </div>
        </section>
        {/* start feture section */}
        <section className='nasef-home-fetures'>
            <div className="container">
                <div className='feture'>
                    <div className='icon'>
                        <i className="fa-solid fa-desktop"></i>
                    </div>
                    <div className='title'>
                        <h5 className='m-0 fw-bold'>
                            فيديوهات
                        </h5>
                        <p className='m-0'>
                            يمكنك مشاهدة الفيديوهات الخاصة
                            بكل درس العديد من مرات المشاهدة
                        </p>
                    </div>
                </div>
                <div className='feture'>
                    <div className='icon'>
                        <i className="fa-solid fa-table"></i>
                    </div>
                    <div className='title'>
                        <h5 className='m-0 fw-bold'>
                            ملخصات
                        </h5>
                        <p className='m-0'>
                            قراءة الملخصات بعد كل درس
                            يتيح لك تثبيت المعلومات وفهمها
                        </p>
                    </div>
                </div>
                <div className='feture'>
                    <div className='icon'>
                        <i className="fa-solid fa-circle-question"></i>
                    </div>
                    <div className='title'>
                        <h5 className='m-0 fw-bold'>
                            أسئلة
                        </h5>
                        <p className='m-0'>
                            لك العديد من الأسئلة بعد كل درس يمكنك حل الأسئلة ومعرفة مستواك ودرجتك
                        </p>
                    </div>
                </div>
                <div className='feture'>
                    <div className='icon'>
                        <i class="fa-solid fa-hand-pointer"></i>
                    </div>
                    <div className='title'>
                        <h5 className='m-0 fw-bold'>
                            أختبارات
                        </h5>
                        <p className='m-0'>
                            عمل اختبارات اسبوعية وشهرية شاملة جميع انواع الأسئلة والأفكار
                        </p>
                    </div>
                </div>
            </div>
        </section>
        {/* separate */}
        <div className="separate"> </div>
        {/* start courses section */}
        <section className="nasef-courses">
            <div className="container">
                <div className="section-header">
                    <h3 className=''>
                        <i className="fa-solid fa-flask"></i>
                        كورسات جميع السنين
                        <img src={orangeBg} alt="" />
                    </h3>
                </div>
                <div className="courses-content">
                    <Link to={`/courses?year=1`} className="year-box d-block">
                        <div className="image">
                            <img src={firstYaer} alt="" />
                        </div>
                        <div className="text">
                            <h4>
                                الصف الاول الثانوي
                            </h4>
                            <p className='m-0'>
                                جميع الكورسات للصف الاول الثانوي
                            </p>
                        </div>
                    </Link>
                    <Link to={`/courses?year=2`} className="year-box d-block">
                        <div className="image">
                            <img src={secoundYaer} alt="" />
                        </div>
                        <div className="text">
                            <h4>
                                الصف الثاني الثانوي
                            </h4>
                            <p className='m-0'>
                                جميع الكورسات للصف الثاني الثانوي
                            </p>
                        </div>
                    </Link>
                    <Link to={`/courses?year=3`} className="year-box d-block">
                        <div className="image">
                            <img src={thirdYaer} alt="" />
                        </div>
                        <div className="text">
                            <h4>
                                الصف الثالث الثانوي
                            </h4>
                            <p className='m-0'>
                                جميع الكورسات للصف الثالث الثانوي
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
        </section>
    </>
}
