import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import { useNavigate, useParams } from 'react-router-dom'
import CSVMake from '../../../CSVMake'

export default function CourseSubscriptions() {

    const navigation = useNavigate()

    const { course_id , course_name } = useParams()
    const { userAuth } = useContext(auth)
    const [studentsData, setstudentsData] = useState(false)
    const [studentCount, setstudentCount] = useState(0)

    const getStudentInCourse = () => {
        axios.get(`${config.basURL}dashboard/student-in-course/${course_id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data)
            setstudentsData(res.data)
            setstudentCount(res.data.length)
        }).catch(err => {
            console.log(err)
        })
    }

    const exportCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            name: 'أسم الطالب',
            code: 'الكود',
            user: ['username', ' رقم الطالب'],
            parent_phone: 'رقم ولي الامر',
            parent_phone_2: '2 رقم ولي الامر',
            whatsapp_phone: ' رقم واتساب ',
            year: ['name', 'السنه الدراسية'],
            place: ['name', 'المكان'],
            division: 'الشعبه',
        }).setData(studentsData, {
            user: 'username',
            year: 'name',
            place: 'name',
        }).exportFile(` بيانات الطلاب - صفحه رقم (  ) `)
    }

    useEffect(() => {
        getStudentInCourse()
    }, [userAuth])

    return <>
        <div className="course-subscriptions container-fluid">
            <div className="section-header">
                <h3 className='m-0 p-0'>
                    الطلاب المشتركين في كورس { course_name }  : ( { studentCount } )
                </h3>
                {
                    studentsData && <button className='export-file my-4' onClick={exportCsv}>
                        <i className="fa-regular fa-file-excel"></i>
                        انشاء ملف Excel
                    </button>
                }
            </div>
            <table className='students-info student-table'>
                <thead>
                    <tr>
                        <th> التسلسل </th>
                        <th>
                            أسم الطالب
                        </th>
                        <th>
                            كود الطالب
                        </th>
                        <th>
                            رقم الطالب
                        </th>
                        <th>
                            رقم ولي الامر
                        </th>
                        <th>
                            السنه الدراسية
                        </th>
                        <th>
                            نوع التعليم
                        </th>
                        <th>
                            المكان
                        </th>
                        <th>
                            الايميل
                        </th>
                        <th>
                            نوع الجهاز المسجل بيه
                        </th>
                        {/* <th>

                    </th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        studentsData ? studentsData.length > 0 ? studentsData.map((student, index) => <tr key={index} style={{ marginBottom: '15px' }}>
                            <td className='image' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {/* <img src={avatar} alt="" className='w-100 h-100' /> */}
                                {index + 1}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.name}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.code}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.user.username}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.parent_phone}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.year && student.year.name}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.type_education && student.type_education.name}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.place ? student.place.name : '--'}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.user.email.length > 0 ? student.user.email : '--'}
                            </td>
                            <td onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                Phone
                            </td>
                            {/* <td>
                            <button className='delete' onClick={() => deleteStudent(student.id)}>
                                <i className="fa-regular fa-trash-can"></i>
                            </button>
                        </td> */}
                        </tr>) : <tr> <td colSpan='8' className='w-100 text-center'> لا يوجد طلاب </td> </tr> : <tr><td className='w-100' colSpan={9}> <Placeholder /> </td></tr>
                    }
                </tbody>
            </table>
        </div>
    </>
}
