import React, { useContext, useEffect, useState } from 'react'
import userImage from '../../../assets/default-user-image.png'
import CircularProgress from './../../../Components/CircularProgress';
import { auth } from '../../../Context/AuthStore';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';
import Validator from '../../../Validator';
import { type } from '@testing-library/user-event/dist/type';

export default function Profile() {

    const { userAuth } = useContext(auth);

    const [loading, setLoading] = useState(false)
    const [isCode, setIsCode] = useState(false)

    const [years, setYears] = useState([])
    const [types, setTypes] = useState([])
    const [places, setplaces] = useState([])

    const [studentCode, setStudentCode] = useState('')

    const [lastName, setLastName] = useState('')
    const [studentData, setstudentData] = useState(false)

    const [updateData, setupdateData] = useState({})

    const [errors, seterrors] = useState({})


    const updateUserData = (e) => {
        let updatedData = { ...updateData };
        updatedData[e.target.name] = e.target.value;
        setupdateData(updatedData)
    }

    // get years and type education and places
    const getUserYearEducation = () => {

        axios.get(`${config.basURL}student/student-extra`, {
            headers: {
                'Authorization': config.ApiKey
            }
        }).then((res) => {

            let response = [...years];
            response = res.data.years;
            setYears(response)

            let typeResponse = [...types];
            typeResponse = res.data.type_education;
            setTypes(typeResponse)

            let placeResponse = [...places]
            typeResponse = res.data.places;
            setplaces(placeResponse)

        }).catch((err) => console.log(err))
    }

    // get user data
    const getUserProfileData = () => {
        axios.get(`${config.basURL}student/student-profile`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
            }
        }).then((res) => {

            console.log(res.data)
            // student data
            setstudentData(res.data)

            setIsCode(res.data.by_code)
            setStudentCode(res.data.code)

            // set last name value
            let lastname = res.data.name.split(' ').slice(1).join(' ');
            setLastName(lastname)

        }).catch((err) => {
            console.log(err)
        })
    }

    const sendData = () => {
        setLoading(true)
        let data = { ...updateData }
        let validation = new Validator;

        if (isCode && studentData.parent_phone === null) {
            let requiredInputs = ['name', 'username', 'parent_phone', 'parent_phone_2', 'whatsapp_phone', 'year', 'division']
            // check if all input fill
            requiredInputs.map(key => {
                if (data[key] !== undefined) {
                    validation.setKey(key).setValue(data[key]).required()
                } else {
                    validation.setKey(key).addError()
                }
            })
            // check if have errors or not
            if (validation.haveError) {
                setLoading(false)
                seterrors(validation.getErrors())
                return false;
            }
        }

        // validation phase
        if (Object.keys(data).length === 0) {
            setLoading(false)
            Swal.fire({
                text: 'لا يوجد اي تعديل',
                icon: 'warning'
            })
            return;
        } else {
            // console.log(data)
            Object.keys(data).map(key => {
                switch (key) {
                    case 'name':
                        validation.setKey('name').setValue(data.name).required().is_arabic().words_count(4)
                        break;
                    case 'username':
                        validation.setKey('username').setValue(data.username).required().len(11).number()
                        break;
                    case 'parent_phone_2':
                        validation.setKey('parent_phone_2').setValue(data.parent_phone_2).required().len(11).number().notMatch(data.username).notMatch(data.parent_phone)
                        break;
                    case 'parent_phone':
                        validation.setKey('parent_phone').setValue(data.parent_phone).required().len(11).number().notMatch(data.username).notMatch(data.parent_phone_2)
                        break;
                    case 'whatsapp_phone':
                        validation.setKey('whatsapp_phone').setValue(data.whatsapp_phone).required().len(11).number()
                        break;
                    case 'year':
                        validation.setKey('year').setValue(data.year).required()
                        break;
                    default:
                        data.division && validation.setKey('division').setValue(data.division).required()
                        break;
                }
            })

            seterrors(validation.getErrors())
            // console.log(validation.getErrors(), data)

            if (validation.haveError) {
                setLoading(false)
                let errors = validation.getErrors()
                let errorsDisplay = []
                Object.keys(errors).map(key => errorsDisplay.push(`${errors[key]}`))

                Swal.fire({
                    text: errorsDisplay.join('\n'),
                    icon: 'error'
                })
                return;
            }
        }

        // sending data phase
        axios.put(`${config.basURL}student/student-update`, data, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
            }
        }).then(res => {
            console.log(res.data)
            setLoading(false)
            getUserProfileData()
            setupdateData({})
            if (res.data.user_errors) {
                let errors = []
                Object.keys(res.data.user_errors).map(error => {
                    errors.push(res.data.user_errors[error])
                })
                Swal.fire({
                    text: errors.join('\n'),
                    icon: 'error'
                })
            } else {
                Swal.fire({
                    text: 'تم تعديل البيانات بنجاح',
                    icon: 'success'
                })
            }
        }).catch(err => {
            setLoading(false)
            console.log(err)
        })

    }

    useEffect(() => {
        getUserProfileData();
        getUserYearEducation();
    }, [])

    return <>
        <div className='container profile-section'>

            <div className='profile-image-progress'>
                <div className='user-image'>
                    <div className='image'>
                        <img src={userImage} alt='' className='w-100 h-100' />
                    </div>
                </div>
            </div>

            <div className='top row gap-2 py-3'>
                <div className='name-email col-lg-6'>
                    <div className='name mb-3'>
                        <div className='group'>
                            <h6>
                                الاسم رباعي باللغة العربية
                            </h6>
                            <div className='inputs'>
                                {
                                    studentData && (isCode ? (studentData.name === null ?
                                        <>
                                            <input type='text' name='name' onChange={updateUserData} />
                                            {/* <input type='text' name='lastName' onChange={updateUserData} /> */}
                                        </> : <p> {studentData.name} </p>) :
                                        <>
                                            <input type='text' name='name' placeholder={studentData.name} onChange={updateUserData} />
                                            {/* <input type='text' name='lastName' placeholder={lastName} onChange={updateUserData} /> */}
                                        </>)
                                }
                            </div>
                            <ul className={errors['name'] ? '' : 'd-none'}>
                                {
                                    errors['name'] && Object.values(errors['name']).map(error => <li className='text-danger'> {error}  </li>)
                                }
                            </ul>

                        </div>
                        <div className="group year-education">
                            <div className="selects w-100">
                                <div className="group w-100">
                                    <h6>
                                        السنه الدراسيه
                                    </h6>
                                    {

                                        studentData && years && (isCode ? (studentData.year === null ?
                                            <>
                                                <select name="year" onChange={updateUserData}>
                                                    <option> اختر السنه الدراسيه </option>
                                                    {
                                                        years && years.filter(y => y.name != 'الكل').map(year => <option value={year.id} >{year.name}</option>)
                                                    }
                                                </select>
                                            </> : <p> {studentData.year.name} </p>) :
                                            <>
                                                <select name="year" onChange={updateUserData}>
                                                    {
                                                        years && years.filter(y => y.name != 'الكل').map(year => <option value={year.id} selected={year.id === studentData.year.id ? true : false} >{year.name}</option>)
                                                    }
                                                </select>
                                            </>)
                                    }
                                    <ul className={errors['year'] ? '' : 'd-none'}>
                                        {
                                            errors['year'] && Object.values(errors['year']).map(error => <li className='text-danger'> {error}  </li>)
                                        }
                                    </ul>
                                </div>

                                {/* <div className="group ">
                                    <h6>
                                        التعليم
                                    </h6>
                                    {
                                        studentData && types && (isCode ? (studentData.type_education === null ?
                                            <>
                                                <select name="type_education" onChange={updateUserData}>
                                                    {
                                                        types && types.map(year => <option value={year.id} >{year.name}</option>)
                                                    }
                                                </select>
                                            </> : <p> {studentData.type_education.name} </p>) :
                                            <>
                                                <select name="year" onChange={updateUserData}>
                                                    {
                                                        years && years.map(year => <option value={year.id} selected={year.id === studentData.type_education.id ? true : false} >{year.name}</option>)
                                                    }
                                                </select>
                                            </>)
                                    }
                                </div> */}

                            </div>
                        </div>
                    </div>
                    <div className="group division">
                        <div className="group w-100">
                            <h6>
                                الشعبه
                            </h6>
                            {
                                studentData && (isCode ? (studentData.division === null ?
                                    <>
                                        <select className='w-100' name="division" onChange={updateUserData}>
                                            <option > اختر الشعبه </option>
                                            <option value={' علمي علوم '} > علمي علوم  </option>
                                            <option value={' علمي رياضه '}> علمي رياضه  </option>
                                            <option value={' ازهر'}> ازهر </option>
                                        </select>
                                    </> : <p> {studentData.division} </p>) :
                                    <>
                                        <select className='w-100' name="division" onChange={updateUserData}>
                                            <option value={' علمي علوم '} > علمي علوم  </option>
                                            <option value={' علمي رياضه '}> علمي رياضه  </option>
                                            <option value={' ازهر'}> ازهر </option>
                                        </select>
                                    </>)
                            }
                            <ul className={errors['division'] ? '' : 'd-none'}>
                                {
                                    errors['division'] && Object.values(errors['division']).map(error => <li className='text-danger'> {error}  </li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='place mb-4'>
                        <div className="group">
                            <h6>
                                مكان التسجيل
                            </h6>
                            {
                                studentData && studentData.place && studentData.place.name
                            }
                        </div>
                        {
                            isCode &&
                            <div className='group'>
                                <h6>
                                    الكود
                                </h6>
                                <p>
                                    {studentCode}
                                </p>
                            </div>
                        }
                    </div>
                    <div className='email'>
                        <div className='group'>
                            <h6>
                                البريد الالكتروني
                            </h6>
                            <div>
                                {
                                    studentData && (isCode ? (studentData.user.email === '' && studentData.name == null ?
                                        <>
                                            <input onChange={updateUserData} type='email' name='email' />
                                        </> : <p> {studentData.user.email} </p>) :
                                        <>
                                            <input onChange={updateUserData} type='email' name='email' placeholder={studentData.user.email} />
                                        </>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-5 birthdate-phone'>
                    {/* <div className='birthdate'>
                        <div className='group'>
                            <h6>
                                التاريخ الميلادي
                            </h6>
                            <div>
                                <input type='date' name='birthdate' />
                            </div>
                        </div>
                    </div> */}
                    <div className='phone'>
                        <div className='group'>
                            <h6>
                                رقم الهاتف
                            </h6>
                            <div>
                                {
                                    studentData && (isCode ? (studentData.user.username == studentData.code ?
                                        <>
                                            <input onChange={updateUserData} type='text' name='username' maxLength={11} />
                                        </> : <p> {studentData.user.username} </p>) :
                                        <>
                                            <input onChange={updateUserData} type='text' name='username' placeholder={studentData.user.username} maxLength={11} />
                                        </>)
                                }
                            </div>
                            <ul className={errors['username'] ? '' : 'd-none'}>
                                {
                                    errors['username'] && Object.values(errors['username']).map(error => <li className='text-danger'> {error}  </li>)
                                }
                            </ul>
                        </div>
                        <div className='group mt-3'>
                            <h6>
                                رقم هاتف ولي الامر
                            </h6>
                            <div>
                                {
                                    studentData && (isCode ? (studentData.parent_phone === null ?
                                        <>
                                            <input onChange={updateUserData} type='text' name='parent_phone' maxLength={11} />
                                        </> : <p> {studentData.parent_phone} </p>) :
                                        <>
                                            <input onChange={updateUserData} type='text' name='parent_phone' placeholder={studentData.parent_phone} maxLength={11} />
                                        </>)
                                }
                            </div>
                            <ul className={errors['parent_phone'] ? '' : 'd-none'}>
                                {
                                    errors['parent_phone'] && Object.values(errors['parent_phone']).map(error => <li className='text-danger'> {error}  </li>)
                                }
                            </ul>
                        </div>
                        <div className='group mt-3'>
                            <h6>
                                رقم ولي الامر 2
                            </h6>
                            <div>
                                {
                                    studentData && (isCode ? (studentData.parent_phone_2 === null ?
                                        <>
                                            <input onChange={updateUserData} type='text' name='parent_phone_2' maxLength={11} />
                                        </> : <p> {studentData.parent_phone_2} </p>) :
                                        <>
                                            <input onChange={updateUserData} type='text' name='parent_phone_2' placeholder={studentData.parent_phone_2} maxLength={11} />
                                        </>)
                                }
                            </div>
                            <ul className={errors['parent_phone_2'] ? '' : 'd-none'}>
                                {
                                    errors['parent_phone_2'] && Object.values(errors['parent_phone_2']).map(error => <li className='text-danger'> {error}  </li>)
                                }
                            </ul>
                        </div>
                        <div className='group mt-3'>
                            <h6>
                                رقم واتساب
                            </h6>
                            <div>
                                {
                                    studentData && (isCode ? (studentData.whatsapp_phone === null ?
                                        <>
                                            <input onChange={updateUserData} type='text' name='whatsapp_phone' maxLength={11} />
                                        </> : <p> {studentData.whatsapp_phone} </p>) :
                                        <>
                                            <input onChange={updateUserData} type='text' name='whatsapp_phone' placeholder={studentData.whatsapp_phone} maxLength={11} />
                                        </>)
                                }
                            </div>
                            <ul className={errors['whatsapp_phone'] ? '' : 'd-none'}>
                                {
                                    errors['whatsapp_phone'] && Object.values(errors['whatsapp_phone']).map(error => <li className='text-danger'> {error}  </li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='save-data py-3'>
                {
                    isCode ? (studentData.name == null && <button onClick={sendData}>
                        {
                            loading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'حفظ البيانات'
                        }
                    </button>) : <button onClick={sendData}>
                        {
                            loading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'حفظ البيانات'
                        }
                    </button>
                }
            </div>
        </div >
    </>
}
