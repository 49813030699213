import React from "react";

export default function NotificationBox() {
  return (
    <>
      <div className="col-lg-12 notification-box">
        <h6 className="notification-title">أسم الكورس</h6>
        <p className="notification-body">شرح تفصيلي لالمحتوا الداخلي للكورس.</p>
        <p className="notification-time">منذ 1 ساعة</p>
        <div className="pin-icon">
          <i class="fa-solid fa-thumbtack"></i>
        </div>
        <div className="new-badge">جديد</div>
      </div>
    </>
  );
}
