import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../App.css';
import Swal from 'sweetalert2';

export default function Layout() {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {

    // function logAllEvents(element) {
    //   let events = [
    //     'click', 'contextmenu'
    //   ]

    //   let phoneRegex = /^0((13|2[2-4]|3|4[05-8]|5[05]|6[24-689]|8[2468]|9[235-7])\d{7}|1[0125]\d{8})$/

    //   events.forEach(event => {
    //     element.addEventListener(event, (e) => {
    //       // user in login page
    //       if (location.pathname == '/auth') {
    //         // check if choose login by code or login by username and password
    //         if (e.target.localName == 'input' && e.target.getAttribute('data-inspect') == 'phonenumber') {
    //           e.target.addEventListener('blur', () => {
    //             if (!phoneRegex.test(e.target.value)) {
    //                 document.querySelector('.google-login').style.cssText = 'border: 1px solid red;'
    //             }
    //           })
    //         }
    //       }
    //     })
    //   })
    // }

    // logAllEvents(document)

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [location.pathname])

  const showSwal = (message, icon, redirect = '') => {
    Swal.fire({
      text: message,
      didClose: () => redirect && navigate(redirect),
      icon: icon
    })
  }
  return <>
    <Header></Header>
    <Outlet context={{ showSwal }}></Outlet>
    <Footer></Footer>
  </>
}
