import React from 'react'
import securityImage from '../../../assets/security.svg'

export default function SecurityAndLogin() {
  return <>
    <div className='security-login-container container'>
        <div className='image'>
            <img src={securityImage} alt=''/>
        </div>
        <div className='quick-info'>
            <div className='login-in-week'>
                <div className='icon'>
                    <i class="fa-solid fa-briefcase"></i>
                    <p className='m-0 p-0'>
                        عدد مرات تسجيل الدخول و الخروج خلال الاسبوع 
                    </p>
                </div>
                <div className='login-logout-count'>
                    <div className='login-count'>
                        <span>5</span>
                        <i class="fa-solid fa-up-long"></i>
                    </div>
                    <div className='logout-count'>
                        <span>4</span>
                        <i class="fa-solid fa-down-long"></i>
                    </div>
                </div>
            </div>
            <div className='login-in-day'>
                <div className='icon'>
                    <i class="fa-solid fa-briefcase"></i>
                    <p className='m-0 p-0'>
                    عدد مرات تسجيل الدخول و الخروج خلال اليوم
                    </p>
                </div>
                <div className='login-logout-count'>
                    <div className='login-count'>
                        <span>10</span>
                        <i class="fa-solid fa-up-long"></i>
                    </div>
                    <div className='logout-count'>
                        <span>11</span>
                        <i class="fa-solid fa-down-long"></i>
                    </div>
                </div>
            </div>
        </div>
        <div className='table-section'>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">نوع الجهاز</th>
                    <th scope="col">أسم الجهاز</th>
                    <th scope="col">نظام التشغيل</th>
                    <th scope="col">المتصفح</th>
                    <th scope="col">أخر نشاط</th>
                    <th scope="col">تاريخ الدخول</th>
                    <th scope="col">تسجيل خروج</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <i class="fa-solid fa-display"></i>
                    </td>
                    <td>
                    Unknown
                    </td>
                    <td>
                    Windows
                    </td>
                    <td>
                    Chrome
                    </td>
                    <td>
                    00:15 / 5 مارس 2024
                    </td>
                    <td>
                    03:43 / 9 فبراير  2024
                    </td>
                    <td>
                        <i class="fa-solid fa-trash-can"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>
  </>
}
