import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import CourseFileBox from '../../../Components/Dashboard/Course/CourseFileBox'
import Validator from '../../../Validator'

export default function CourseFiles() {

    const { userAuth } = useContext(auth);
    const { course_id , type } = useParams();
    const [allCourseFiles, setAllCourseFiles] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [uploadedFile, setUploadedFile] = useState({
        file: ''
    })

    const updateFile = (e) => {
        let data = { ...uploadedFile }
        data["file"] = e.target.files[0]
        setUploadedFile(data);
    }

    const getAllCourseFiles = () => {
        axios.get(`${config.basURL}dashboard/${type}-file-overview/${course_id}` , {
            headers: {
                'Authorization': config.ApiKey ,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data.lesson_files)
            type != 'course' ? setAllCourseFiles(res.data.lesson_files) : setAllCourseFiles(res.data.course_files)
        }).catch(err => {
            console.log(err)
        })
    }

    const addFile = (e) => {    
        e.preventDefault()
        setIsUploading(true)
        let validate = new Validator ;
        validate.setKey('file').setValue(uploadedFile.file).required();

        if(validate.haveError){
            Swal.fire({
                text: 'برجاء اختيار ملف' ,
                icon: 'error'
            })
            setIsUploading(false)
            return;
        }

        axios.post(`${config.basURL}dashboard/${type}-file-overview/${course_id}` ,uploadedFile , {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            setIsUploading(false)
            getAllCourseFiles()
            Swal.fire({
                text: 'تم الاضافه بنجاح' , 
                icon: 'success'
            })
        }).catch(err => {
            setIsUploading(false)
            console.log(err)
        })
    }

    useEffect(()=>{
        getAllCourseFiles()
    } , [userAuth])

    

    return <>
        <div className="container-fluid course-files-page">
            <h1>
                فايلات
            </h1>
            <form  className='add-files' onSubmit={addFile}>
                <div class="input-group">
                    <input type="file" name='file' className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" onChange={updateFile} required/>
                </div>
                <button className='add' disabled={isUploading ? true : false}> 
                    {
                        isUploading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'اضافه'
                    }
                </button>
            </form>
            <div className="files row gap-2">
                {
                    allCourseFiles ? (allCourseFiles.length > 0 ? allCourseFiles.map(file => <CourseFileBox type={type} data={file} course_id={course_id} refresh={getAllCourseFiles}/>) : <h5 className='col-12 text-center'>لا يوجد فايلات في هذا  { type != 'course' ? ' الدرس ' : ' الكورس ' } </h5>) : <Placeholder />
                }
            </div>
        </div>
    </>
}
