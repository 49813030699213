import React from 'react'
import { Link } from 'react-router-dom'

export default function Users() {
    return <>
        <div className="container-fluid dashboard-users-page">
            <h1>
                المستخدمين
            </h1>
            <div className="super-admins">
                <div className="section-header">
                    <h6>Super Admins</h6>
                    <span className="line"></span>
                </div>
                <div className="content">
                    <div className="user-row">
                        <div className="right">
                            <div className="image">
                                <img src="" alt="" className='w-100 h-100' />
                            </div>
                            <div className="user-info">
                                <p>اسم المستخدم</p>
                                <p>Username@gmail.com</p>
                            </div>
                        </div>
                        <div className="left">
                            <button className='edit'><i className="fa-solid fa-pen"></i></button>
                            <button className='delete'><i class="fa-regular fa-trash-can"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="admins">
                <div className="section-header">
                    <h6>Admins</h6>
                    <span className="line"></span>
                </div>
                <div className="content">
                    <div className="user-row">
                        <div className="right">
                            <div className="image">
                                <img src="" alt="" className='w-100 h-100' />
                            </div>
                            <div className="user-info">
                                <p>اسم المستخدم</p>
                                <p>Username@gmail.com</p>
                            </div>
                        </div>
                        <div className="left">
                            <button className='edit'><i className="fa-solid fa-pen"></i></button>
                            <button className='delete'><i class="fa-regular fa-trash-can"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-new">
                <span className="line"></span>
                <Link to={'/dashboard/add-user'}>
                    <div className="icon">
                        <i className="fa-solid fa-plus"></i>
                    </div>
                    <p>
                        اضافة مستخدم
                    </p>
                </Link>
            </div>
        </div>
    </>
}
