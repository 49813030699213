import React, { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import teacher from '../../assets/Mobile login Customizable Cartoon Illustrations _ Bro Style.jfif'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { createPortal } from 'react-dom'

export default function AuthLayout() {

    const [haveError, setHaveError] = useState(false)
    const [popupErrors, setPopupErrors] = useState([])

    const showSwal = () => {
        Swal.fire({
            didOpen: () => setHaveError(true),
            didClose: () => setHaveError(false),
            icon: "error"
        })
    }

    const createPopupErrors = (errors) => {
        let allErrors = [];
        for (const key in errors) {
            allErrors.push(`${errors[key][0]}`)
        }
        setPopupErrors(allErrors)
    }

    return <>

        {
            haveError && createPortal(
                popupErrors.map(err => <p>{err}</p>),
                Swal.getHtmlContainer()
            )
        }
        <div className='auth-layout'>

            <div className='right container h-100'>
                <Outlet context={{ setHaveError, showSwal, createPopupErrors }}></Outlet>
            </div>
            <div className='left-image'>
                <img className='w-100 h-100' src={teacher} />
                <Link className='back-btn' to={"/"}>
                    <i className="fa-solid fa-arrow-left"></i>
                </Link>
            </div>
        </div>
    </>
}
