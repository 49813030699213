import React, { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

export default function CourseUniteBox(props) {

  const circleElement = useRef();
  const lessons = useRef();
  const {course_id} = useParams()
  const [lessonID, setLessonID] = useState(0)

  const selectAction = (e) => {
    // remove select class from all elements
    let elements = circleElement.current.children;
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("selected");
    }
    // add selected class to selected element
    e.target.className === "action-icon"
      ? e.target.classList.add("selected")
      : e.target.parentElement.classList.add("selected");
  };

  const selectLesson = (e) => {
    setLessonID(e.target.getAttribute('data-id'))
    // remove select class from all elements
    let elements = lessons.current.children;
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("selected");
    }
    // add selected class to selected element
    e.target.classList.add("selected")
  }

  return (
    <>
      <div className="course-unit-box col-lg-2 p-0">
        <h6 className="title w-100"> {props.data && props.data.name} </h6>
        {/* <div className="action" ref={circleElement}>
          <div
            className={`action-icon`}
            data-action="video"
            onClick={selectAction}
          >
            <i className="fa-solid fa-display"></i>
            <p className="m-0 p-0">فيديو</p>
          </div>
          <div
            className={`action-icon`}
            data-action="exam"
            onClick={selectAction}
          >
            <i className="fa-solid fa-table-cells-large"></i>
            <p className="m-0 p-0">الكويز</p>
          </div>
          <div
            className={`action-icon`}
            data-action="homework"
            onClick={selectAction}
          >
            <i className="fa-solid fa-book"></i>
            <p className="m-0 p-0">الواجب</p>
          </div>
        </div> */}
        <ul className="lessons w-100" ref={lessons}>
          {
            props.data && props.data.unit_lessons.map((lesson) => <>
            <li key={lesson.id} data-id={lesson.id} onClick={selectLesson} >
              <Link to={`/my-courses/${course_id}/watch?unit=${props.data.id}&lesson=${lesson.id}`}>{lesson.name}</Link>
            </li>
            {
              lesson.lesson_file &&  lesson.lesson_file.map(file => <li>
                <a href={file.file} target="_blank"> {
                decodeURIComponent(file.file.split('/')[file.file.split('/').length - 1])
              } </a>
              </li> ) 
            }
            </>)
          }
        </ul>
        {/* <Link to={`/my-courses/${course_id}/watch?unit=1&lesson=${lessonID}`} className="start">
          البدء
        </Link> */}
      </div>
    </>
  );
}
