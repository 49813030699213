import React, { useContext, useEffect, useState } from 'react'
import DashboardCourseBox from '../../../Components/Dashboard/Course/DashboardCourseBox'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import Swal from 'sweetalert2'

export default function DashboardCourses() {

    const [coursesType, setcoursesType] = useState(null)

    const { userAuth } = useContext(auth);
    const [courses, setCourses] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isSinding, setIsSinding] = useState(false)
    const [year, setYear] = useState([])
    const [places, setPlaces] = useState([])

    const [updatedData, setupdatedData] = useState({})
    const [openPopup, setOpenPopup] = useState(false)
    const [currentUpdateCourse, setCurrentUpdateCourse] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)

    const tooglePopup = (data) => {
        setCurrentUpdateCourse(data)
        openPopup ? setOpenPopup(false) : setOpenPopup(true)
        setupdatedData({})
    }
    const closePopup = (e) => {
        if (e.target.classList.contains('update-course-popup')) {
            setOpenPopup(false)
            setCurrentUpdateCourse(false)
        }
    }

    const addUpdateData = (e) => {
        let data = { ...updatedData };
        if (e.target.name === 'cover') {
            data['cover'] = e.target.files[0]
        } else {
            data[e.target.name] = e.target.value
        }
        setupdatedData(data)
    }

    const [newCourseData, setNewCourseData] = useState({
        name: '',
        about: '',
        cover: '',
        price: 0,
        year: '',
        place: '',
        free: false,
    })

    const update = (e) => {
        let data = { ...newCourseData }
        data[e.target.name] = e.target.value;
        setNewCourseData(data)
    }

    const clickUpdate = (e) => {
        let elemnts = e.target.parentElement.children;
        for (let i = 0; i < elemnts.length; i++) {
            elemnts[i].classList.remove('active')
        }
        e.target.classList.add('active')
        let data = { ...newCourseData }
        data[e.target.getAttribute('data-name')] = Number(e.target.getAttribute('data-id'));
        setNewCourseData(data)
    }

    const updateImageData = (e) => {
        let data = { ...newCourseData }
        data["cover"] = e.target.files[0]
        setNewCourseData(data);
    }

    // const getCourses = () => {
    //     setIsLoading(true)
    //     axios.get(`${config.basURL}dashboard/course-overview`, {
    //         headers: {
    //             'Authorization': config.ApiKey,
    //             'auth': `Bearer ${userAuth}`,

    //         }
    //     }).then((res) => {
    //         setIsLoading(false)
    //         setCourses(res.data.courses)
    //     }).catch((err) => {
    //         console.log(err)
    //     })
    // }

    const getExtra = () => {
        axios.get(`${config.basURL}student/student-extra`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': userAuth
            }
        }).then((res) => {
            setYear(res.data.years)
            setPlaces(res.data.places)
        }).catch((err) => {
            console.log(err)
        })
    }

    const send = (e) => {
        e.preventDefault()
        setIsSinding(true)
        let data = {...newCourseData} ;
        data.price == 0 ? data.free = true : data.free = false ;
        axios.post(`${config.basURL}dashboard/course-overview`, data, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            setIsSinding(false)
            // getCourses()
            Swal.fire({
                text: 'تم الاضافه بنجاح',
                icon: 'success'
            })
        }).catch((err) => {
            setIsSinding(false)
            console.log(err)
        })
    }

    const deleteCourse = (id) => {
        Swal.fire({
            title: `هل انت متاكد من حذف الكورس`,
            icon: "warning",
            showCancelButton : true ,
            cancelButtonText : 'لا شكرا' ,
            showConfirmButton : true,
            confirmButtonText : 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/course-overview`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`
                    },
                    data: {
                        id_course: id
                    }
                }).then((res) => {
                    // getCourses()
                    Swal.fire({
                        text: 'تم الحذف بنجاح',
                        icon: 'success'
                    })
                }).catch((err) => {
                    console.log(err)
                })
            }
        })
    }

    const sendUpdateData = (id) => {

        if (Object.keys(updatedData).length === 0) {
            setOpenPopup(false)
            Swal.fire({
                text: 'لا يوجد تعديلات',
                icon: 'warning'
            })
            return false;
        }

        axios.put(`${config.basURL}dashboard/course-update/${id}`, updatedData, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            setOpenPopup(false)
            // getCourses()
            Swal.fire({
                text: 'تم التعديل بنجاح' ,
                icon: 'success'
            })
        }).catch((err) => console.log(err))
    }

    const filterCourses = (filter = null) => {
        setIsLoading(true)
        setCourses(false)
        setcoursesType(() => filter == null ? 'all' : filter)
        let link = filter != null ? `${config.basURL}dashboard/course-overview?place_id=${filter}` : `${config.basURL}dashboard/course-overview`
        axios.get(link, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,

            }
        }).then((res) => {
            setIsLoading(false)
            setCourses(res.data.courses)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        // getCourses()
        getExtra()
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-courses">
            <h1>
                الكورسات
            </h1>
            <form className="add-new-course" onSubmit={send} encType='multipart/form-data'>
                <input type="file" name="cover" id="course-image-input" className='d-none' onChange={updateImageData} />
                <label className="course-image d-block" htmlFor='course-image-input'>
                    <p>
                        تحميل الصورة
                    </p>
                    <img src={newCourseData.cover ? URL.createObjectURL(newCourseData.cover) : ''} alt="" className='w-100 h-100' />
                </label>

                <div className='course-title-desc'>
                    <input type="text" className='course-title' name='name' placeholder='عناون الكورس' onChange={update} />
                    <textarea name="about" id="" className='course-desc' placeholder='وصف الكورس' onChange={update}></textarea>
                </div>

                <div className="school-year">
                    <h6>
                        السنة الدراسية
                    </h6>
                    <ul >
                        {
                            year && year.map((y) => <li key={y.id} data-name='year' data-id={y.id} onClick={clickUpdate} > {y.name} </li>)
                        }
                    </ul>
                </div>

                <div className="category-type">
                    <h6>
                        نوع القسم
                    </h6>
                    <ul>
                        {
                            places && places.map(place => <li key={place.id} data-name='place' data-id={place.id} onClick={clickUpdate}> {place.name} </li>)
                        }
                    </ul>
                </div>

                <div className="publish-date">
                    {/* <h6>
                        تاريخ النشر
                    </h6>
                    <input type="date" name="publish-date" id="" className='publish-date' /> */}
                    <input type="text" name='price' className='course-price' placeholder='أدخل سعر الكورس' onChange={update} />
                    <button>
                        {
                            isSinding ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'حفظ بيانات الكورس'
                        }

                    </button>
                </div>
            </form>

            <div className='dashboard-courses-filter d-flex justify-content-center align-items-center py-3 my-2'>
                <button onClick={() => filterCourses()} className={`${coursesType == 'all' && 'active'}`}> كل الكورسات </button>
                <button onClick={() => filterCourses(1)} className={`${coursesType == 1 && 'active'}`}> كورسات اونلاين </button>
                <button onClick={() => filterCourses(2)} className={`${coursesType == 2 && 'active'}`}> كورسات سنتر </button>
            </div>

            <div className='courses-rows'>
                {
                    isLoading ? <Placeholder /> : (courses.length > 0 ? courses.map(course => <DashboardCourseBox updateCourse={tooglePopup} deleteCourse={deleteCourse} key={course.id} data={course} />) : <p className='text-center'>لا يوجد كورسات</p>)
                }

            </div>
        </div>

        {/* update popup */}

        <div className={`update-course-popup ${openPopup ? 'open' : ''}`} onClick={closePopup}>
            <div className="box">
                {
                    currentUpdateCourse && <div>
                        <div className="image">
                            <input type="file" name='cover' id='cover' className='d-none' onChange={addUpdateData} />
                            <img src={`${config.basURL}${currentUpdateCourse.cover}`} alt="" className='w-100 h-100' />
                            <label htmlFor="cover"> تغيير الصوره </label>
                        </div>
                        <div className='name-price' >
                            <input type="text" name="name" onChange={addUpdateData} placeholder={currentUpdateCourse.name} />
                            <input type="text" name='price' onChange={addUpdateData} placeholder={currentUpdateCourse.price} />
                        </div>
                        <div className="year-place">
                            <select name="year" id="" onChange={addUpdateData}>
                                {
                                    year && year.map(y => <option value={y.id} selected={currentUpdateCourse.year.id === y.id ? true : false} > {y.name} </option>)
                                }
                            </select>
                            <select name="year" id="" onChange={addUpdateData}>
                                {
                                    places && places.map(place => <option value={place.id} selected={currentUpdateCourse.place.id === place.id ? true : false} > {place.name} </option>)
                                }
                            </select>
                        </div>
                        <textarea name="about" id="" onChange={addUpdateData} placeholder={currentUpdateCourse.about}></textarea>
                        <button className='update-course' onClick={() => sendUpdateData(currentUpdateCourse.id)}> 
                        {isUpdating ? <i className="fa-solid fa-circle-notch fa-spin"></i>  : 'تعديل'}
                        </button>
                    </div>
                }
            </div>
        </div>
    </>
}
