import React, { useContext, useEffect, useState } from 'react'
import MyCoursesBox from '../../../Components/Profile/MyCoursesBox'
import config from '../../../config';
import axios from 'axios';
import { auth } from '../../../Context/AuthStore'

export default function UserCourses() {

  const { userAuth } = useContext(auth)

  const [userCourses, setuserCourses] = useState([])

  const getUserCourses = () => {
    axios.get(`${config.basURL}student/student-courses`, {
      headers: {
        'Authorization': config.ApiKey,
        'auth': `Bearer ${userAuth}`
      }
    }).then((res) => {
      let activeCourses = res.data.filter(course => course.active)
      setuserCourses(activeCourses)
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(()=>{
    getUserCourses()
  }, [])

  return <>
    <div className='container my-courses-section'>
      <div className='row'>
        
        {
          userCourses.length > 0 ? userCourses.map(course => <MyCoursesBox active={true} data={course} />)
          :
          <h5 className='text-center'>لا يوجد كورسات حتي الان</h5>
        }
      </div>
    </div>
  </>
}
