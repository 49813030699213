import React, { useEffect , useState} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function NotFound() {

  const location = useLocation();
  const [rigister, setrigister] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if(location.pathname.includes('register')){
      setrigister(true)
    }
  } , [])

  return <>
  <div className="notfound-page">
    {/* {
      rigister ? <>
      <h1> لا يوجد انشاء حسابات جديده الان </h1>
      <button onClick={() => navigate('/auth')} className=""> تسجيل الدخول </button>
      </> : 
    } */}
    <h1>notfound 404 </h1>
  </div>
  </>
}
