import React, { useEffect, useRef } from 'react'
import Swal from 'sweetalert2';

export default function Video({ videoLink, firstTime, sendWatchTry, setIntervalID, setWatchTime, intervalID, sendWatchTime, avilabile, watchTime }) {

    const media = useRef()

    // // video play handler
    useEffect(() => {
        const onVideoPlay = () => {
            // check if user play video at first time to send request 
            if (firstTime) {
                sendWatchTry()
            }
            const id = setInterval(() => {
                setWatchTime((prevTime) => prevTime + 1)
            }, 1000);
            setIntervalID(id)
        }
        media.current.addEventListener('play', onVideoPlay)
        return () => {
            media.current && media.current.removeEventListener('play', onVideoPlay)
        }
    }, [firstTime, watchTime])

    // // pause record user watch time
    useEffect(() => {
        const onVideoPause = () => {
            clearInterval(intervalID)
            sendWatchTime()
        }
        media.current.addEventListener('pause', onVideoPause)
        return () => {
            media.current && media.current.removeEventListener('pause', onVideoPause)
        }
    }, [intervalID, watchTime])

    // // video end handler
    useEffect(() => {
        const onEndedVideo = () => {
            sendWatchTime();
            clearTimeout(intervalID);
        }
        media.current.addEventListener('ended', onEndedVideo)
        return () => {
            media.current && media.current.removeEventListener('ended', onEndedVideo)
        }
    }, [watchTime])

    useEffect(() => {
        if (!avilabile) {
            clearInterval(intervalID)
            Swal.fire({
                text: 'انتهت مشاهداتك لهذا الدرس',
                icon: 'info'
            })
        }
    }, [avilabile])

    return <>
        {
            avilabile ? <video ref={media} className='w-100 rounded' controls>
                <source src={videoLink} type='video/mp4' />
            </video> : <div className="alert alert-info text-center">انتهت مشاهداتك للدرس</div>
        }

    </>
}
